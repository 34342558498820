import type { NextPage } from 'next';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Banner, Slide, Content } from '@app/atoms/hero';
import Heading from '@app/atoms/typography/heading';
import Text from '@app/atoms/typography/text';
import slideone from '@public/images/inner-banner/flight-deal-banner.jpg';

const NotFound: NextPage = () => {
  return (
    <>
      <section className='bannerSection inner'>
        <Banner variant='primary'>
          <Slide src={slideone.src}>
            <Content>
              <Col>
                <div className='sliderData'>
                  <Heading heavy>404</Heading>
                  <Text variant='xl' className='mb-0'>
                    NOT FOUND
                  </Text>
                </div>
              </Col>
            </Content>
          </Slide>
        </Banner>
      </section>

      <section className='not-found-page'>
        <Container>
          <Row>
            <Col md='12' className='justify-content-center'>
              <div className='not-found-inner'>
                <Heading as='h2'>
                  ERROR 404 <br />
                  NOT FOUND
                </Heading>
                <Text>
                  You may have mis-typed the URL. Or the page has been removed.
                  Actually, there is nothing to see here... <br />
                  Click on the links below to do something, Thanks!
                </Text>
                <Button variant='primary'>
                  <Text as='span'>Take me out of here</Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NotFound;
